function validate(mode, value, options) {

  options = options || {};

  /* List of countries that its iso code doesn't match the Swift code fourth and fifth position */
  var swiftCountryExceptions = ['IM', 'GG', 'JE', 'LT'];

  var valid = true;
  switch (mode) {
    case "empty":
      if ($.trim(value) == '') {
        valid = false;
      }
      break;
    case "email-@":
      if (!/^.*\@.*$/.test(value)) {
        valid = false;
      }
      break;
    case "email-dot":
      if (!/^.*\@.*\..*$/.test(value)) {
        valid = false;
      }
      break;
    case "email":
      valid = isValidEmail(value);
      break;
    case "numeric":
      if (!/^([0-9])+$/.test(value)) {
        valid = false;
      }
      break;
    case "alphanumeric":
      if (!/^([a-zA-Z0-9 ])+$/.test(value)) {
        valid = false;
      }
      break;
    case "alphanumeric_extended":
      if (!/^[a-zA-Z0-9 .,\/\(\)\?\:\'\+\-\{\}]+$/.test(value)) {
        valid = false;
      }
      break;
    case "iban":
      if (!(/^([a-zA-Z0-9]{4} ){2,8}[a-zA-Z0-9]{1,4}|[a-zA-Z0-9]{12,34}$/.test(value))) {
        return false;
      }

      // check the country code and find the country specific format
      var iban = value.toUpperCase(); // remove spaces and to upper case

      var country = null;
      if (options.country) {
        country = options['country'].toUpperCase();
        var countrycode = iban.substring(0, 2);

        var greatbritain = "GB";
        var france = "FR";
        var countries_gb = ["GG", "IM", "JE"];
        var countries_fr = ['GF', 'PF', 'GP', 'MQ', 'NC', 'RE', 'PM', 'WF', 'CF'];

        if (countrycode != country) {
          if ((($.inArray(country, countries_gb) == -1) && (countrycode != greatbritain)) &&
            (($.inArray(country, countries_fr) == -1) && (countrycode != france))) {
            return false
          }
        }
      }

      var bbancountrypatterns = {
        'AL': "\\d{8}[\\dA-Z]{16}",
        'AD': "\\d{8}[\\dA-Z]{12}",
        'AT': "\\d{16}",
        'AZ': "[A-Z]{4}[\\dA-Z]{20}",
        'BE': "\\d{12}",
        'BH': "[A-Z]{4}[\\dA-Z]{14}",
        'BA': "\\d{16}",
        'BG': "[A-Z]{4}\\d{6}[\\dA-Z]{8}",
        'CR': "\\d{18}",
        'HR': "\\d{17}",
        'CY': "\\d{8}[\\dA-Z]{16}",
        'CZ': "\\d{20}",
        'DK': "\\d{14}",
        'DO': "[A-Z]{4}\\d{20}",
        'EE': "\\d{16}",
        'FO': "\\d{14}",
        'FI': "\\d{14}",
        'FR': "\\d{10}[\\dA-Z]{11}\\d{2}",
        'GF': "\\d{10}[\\dA-Z]{11}\\d{2}",
        'PF': "\\d{10}[\\dA-Z]{11}\\d{2}",
        'GP': "\\d{10}[\\dA-Z]{11}\\d{2}",
        'MQ': "\\d{10}[\\dA-Z]{11}\\d{2}",
        'NC': "\\d{10}[\\dA-Z]{11}\\d{2}",
        'RE': "\\d{10}[\\dA-Z]{11}\\d{2}",
        'PM': "\\d{10}[\\dA-Z]{11}\\d{2}",
        'WF': "\\d{10}[\\dA-Z]{11}\\d{2}",
        'CF': "\\d{10}[\\dA-Z]{11}\\d{2}",
        'GE': "[\\dA-Z]{2}\\d{16}",
        'DE': "\\d{18}",
        'GI': "[A-Z]{4}[\\dA-Z]{15}",
        'GR': "\\d{7}[\\dA-Z]{16}",
        'GL': "\\d{14}",
        'GT': "[\\dA-Z]{4}[\\dA-Z]{20}",
        'HU': "\\d{24}",
        'IS': "\\d{22}",
        'IE': "[\\dA-Z]{4}\\d{14}",
        'IL': "\\d{19}",
        'IT': "[A-Z]\\d{10}[\\dA-Z]{12}",
        'KZ': "\\d{3}[\\dA-Z]{13}",
        'KW': "[A-Z]{4}[\\dA-Z]{22}",
        'LV': "[A-Z]{4}[\\dA-Z]{13}",
        'LB': "\\d{4}[\\dA-Z]{20}",
        'LI': "\\d{5}[\\dA-Z]{12}",
        'LT': "\\d{16}",
        'LU': "\\d{3}[\\dA-Z]{13}",
        'MK': "\\d{3}[\\dA-Z]{10}\\d{2}",
        'MT': "[A-Z]{4}\\d{5}[\\dA-Z]{18}",
        'MR': "\\d{23}",
        'MU': "[A-Z]{4}\\d{19}[A-Z]{3}",
        'MC': "\\d{10}[\\dA-Z]{11}\\d{2}",
        'MD': "[\\dA-Z]{2}[A-Z0-9]{18}",
        'ME': "\\d{18}",
        'NL': "[A-Z]{4}\\d{10}",
        'NO': "\\d{11}",
        'PK': "[\\dA-Z]{4}\\d{16}",
        'PS': "[\\dA-Z]{4}\\d{21}",
        'PL': "\\d{24}",
        'RO': "[A-Z]{4}[\\dA-Z]{16}",
        'SM': "[A-Z]\\d{10}[\\dA-Z]{12}",
        'SA': "\\d{2}[\\dA-Z]{18}",
        'RS': "\\d{18}",
        'SK': "\\d{20}",
        'SI': "\\d{15}",
        'ES': "\\d{20}",
        'SE': "\\d{20}",
        'CH': "\\d{5}[\\dA-Z]{12}",
        'TN': "\\d{20}",
        'TR': "\\d{5}[\\dA-Z]{17}",
        'AE': "\\d{3}\\d{16}",
        'GB': "[A-Z]{4}\\d{14}",
        'JE': "[A-Z]{4}\\d{14}",
        'IM': "[A-Z]{4}\\d{14}",
        'GG': "[A-Z]{4}\\d{14}",
        'VG': "[\\dA-Z]{4}\\d{16}",
        'UK': "\\d{25}",
        'DZ': "\\d{20}",
        'AO': "\\d{21}",
        'BR': "\\d{23}[A-Z]{1}\\d{1}",
        'BF': "\\d{23}",
        'BI': "\\d{12}",
        'CM': "\\d{23}",
        'CV': "\\d{21}",
        'CG': "\\d{23}",
        'EG': "\\d{25}",
        'GA': "\\d{23}",
        'IR': "\\d{22}",
        'CI': "[A-Z]{1}\\d{23}",
        'MG': "\\d{23}",
        'ML': "[\\dA-Z]{4}\\d{20}",
        'MZ': "\\d{21}",
        'PT': "\\d{21}",
        'SN': "[A-Z]{1}\\d{23}"
      };
      var bbanpattern = bbancountrypatterns[countrycode];
      // As new countries will start using IBAN in the
      // future, we only check if the countrycode is known.
      // This prevents false negatives, while almost all
      // false positives introduced by this, will be caught
      // by the checksum validation below anyway.
      // Strict checking should return FALSE for unknown
      // countries.
      if (typeof bbanpattern !== 'undefined') {
        var ibanregexp = new RegExp("^[A-Z]{2}\\d{2}" + bbanpattern + "$", "");
        if (!(ibanregexp.test(iban))) {
          return false; // invalid country specific format
        }
      }

      if (options.checksum) {
        // now check the checksum, first convert to digits
        var ibancheck = iban.substring(4, iban.length) + iban.substring(0, 4);
        var ibancheckdigits = "";
        var leadingZeroes = true;
        var charAt;
        for (var i = 0; i < ibancheck.length; i++) {
          charAt = ibancheck.charAt(i);
          if (charAt !== "0") {
            leadingZeroes = false;
          }
          if (!leadingZeroes) {
            ibancheckdigits += "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ".indexOf(charAt);
          }
        }

        // calculate the result of: ibancheckdigits % 97
        var cRest = '';
        var cOperator = '';
        for (var p = 0; p < ibancheckdigits.length; p++) {
          var cChar = ibancheckdigits.charAt(p);
          cOperator = '' + cRest + '' + cChar;
          cRest = cOperator % 97;
        }
        valid = (cRest === 1);
      }

      break;
    case "password":
      // check minimum length
      if (value.length < 8) {
        return false;
      }
      // check there are uppercase letters in the password (checking [A-Z] is not enough because we allow characters from all alphabets)
      if (value.toLowerCase() === value) {
        return false;
      }
      // check there are lowercase letters in the password (checking [a-z] is not enough because we allow characters from all alphabets)
      if (value.toUpperCase() === value) {
        return false;
      }
      // check numbers
      if (!/\d/.test(value)) {
        return false;
      }

      return true;
    case "swift":
      if (!(/^[a-zA-Z0-9]{4}[a-zA-Z]{2}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?$/.test(value))) {
        return false;
      }
      break;
    case "swiftChar":
      // Search for invalid SWIFT characters.
      if (!(/^[\sa-zA-Z0-9,\.:'\+\(\)\?'\-/]*?$/.test(value))) {
        return false;
      }
      break;
    case "cnaps":
      if (!(/^[0-9]{12}$/).test(value)) {
        return false;
      }
      break;
    case "swiftCountry":
      var country = null;
      if (options.country) {
        country = options['country'].toUpperCase();
        var countrycode = value.toUpperCase().substring(4, 6);
        if (countrycode !== country &&
          country !== 'CN' && countrycode !== 'HK' &&
          swiftCountryExceptions.indexOf(country) === -1) {
          return false;
        }
      }
      break;
  }
  return valid;
}


// https://github.com/manishsaraan/email-validator/blob/master/index.js
var EMAIL_REGEX = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

function isValidEmail(email) {
  if (!email) return false;

  var emailParts = email.split('@');

  if (emailParts.length !== 2) return false;

  var account = emailParts[0];
  var address = emailParts[1];

  if (account.length > 64) return false;

  else if (address.length > 255) return false;

  var domainParts = address.split('.');
  
  if (domainParts.some(function (part) {
    return part.length > 63;
  })) return false;

  return EMAIL_REGEX.test(email);
};
